'use client';
import { captureException } from '@sentry/nextjs';
import { ErrorView } from 'modules/error';
import { JSX, useState } from 'react';

type TProps = {
  error: Error & { digest?: string };
};

export default function Error({ error }: TProps): JSX.Element {
  const [errorCode] = useState<string>(() => captureException(error));

  return <ErrorView errorCode={errorCode} />;
}
