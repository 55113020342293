import { SimpleImage } from 'components/simple-image';
import { Button, Center, Spacer, Typo } from 'components/ui-kit';
import Link from 'next/link';
import React from 'react';
import styles from './error-view.module.scss';

type TErrorViewProps = {
  errorCode?: string;
  hideErrorLogo?: boolean;
  onReset?: () => void;
};

export const ErrorView = ({
  onReset,
  errorCode,
  hideErrorLogo,
}: TErrorViewProps): JSX.Element => {
  return (
    <>
      <div className={styles.wrapper}>
        {!hideErrorLogo && (
          <>
            <Spacer size={32} />
            <Center>
              <SimpleImage
                layout="fixed"
                src="/images/error-logo.svg"
                alt="Универмаг"
                width={267}
                height={67}
              />
            </Center>
          </>
        )}
        <div className={styles.container}>
          <div>
            <Typo className={styles.text} variant="4xl" align="center">
              С&nbsp;этой страницей что-то не&nbsp;так. Попробуйте вернуться
              на&nbsp;главную.
            </Typo>
            <Spacer size={12} />
            <Typo variant="xl" align="center">
              Код ошибки: {errorCode}
            </Typo>
            <Spacer size={44} />
            <Center>
              <Link href="/">
                <Button
                  className={styles.button}
                  size="xl"
                  variant="secondary"
                  onClick={onReset}
                >
                  На&nbsp;главную
                </Button>
              </Link>
            </Center>
          </div>
        </div>
      </div>
    </>
  );
};
